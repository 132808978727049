/* ==========================================================================
   Accueil
   ========================================================================== */

// .main-accueil {
//   min-height: 50vh;
// }

/* ==========================================================================
  Accueil
  ========================================================================== */

  .parallax-window {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    text-align: center;
    overflow: hidden;
    background: linear-gradient(to bottom, rgba(black, 0) 0%, rgba(black, .6) 100%);

    >a {
      display: inline-block;
      position: relative;
      font-size: 2rem;
      font-family: 'Michroma', sans-serif;
      text-decoration: none;
      text-transform: uppercase;
      font-weight: 700;
      color: white;

      >img {
        @extend .img-responsive;
        max-width: 90%;
        margin: 0 auto;
        transition: transform ease-out .3s;
      }

      >span {
        display: inline-block;
        transition: letter-spacing ease-out .3s;
      }

      @media(min-width: $screen-sm-min) {
        font-size: 3.5rem;

        &:hover {
          opacity: .5;

          >img {
            transform: scale(1.1);
          }

          >span {
            letter-spacing: 1rem;
          }
        }
      }

      @media(min-width: $screen-md-min) {
        font-size: 3rem;
      }
    }
  }


  /* ==========================================================================
    Footer Accueil
    ========================================================================== */

  .footer-accueil {
    @extend .text-center;
    padding: 5% 0;

    h1 {
      font-size: $font-size-large;
    }
  }

  .col-footer-accueil-left {
    @extend .col-sm-6;
    @extend .text-left;

    @media (max-width: $screen-xs-max) {
      text-align: center;
    }
  }

  .col-footer-accueil-right {
    @extend .col-sm-6;
    @extend .text-right;

    @media (max-width: $screen-xs-max) {
      text-align: center;
      padding-top: 20px;
    }
  }


